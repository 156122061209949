/* eslint-disable camelcase */
import React from 'react';
import { Box } from 'theme-ui';
import { Elements, HTMLSerializer, RichText } from 'prismic-reactjs';

import Link from 'components/atoms/Link';
import { normalizeHref } from 'utils/prismic/utils';
import Image from 'components/atoms/Image';
import { RichTextRendererProps } from './types';

type PrismicContentLink =
  | {
      link_type: 'Any';
    }
  | {
      link_type: 'Web';
      url: string | null;
    }
  | {
      link_type: 'Document';
      type: string;
      slug: string | null;
    }
  | {
      link_type: 'Media';
      url: string | null;
    };

const htmlSerializer: HTMLSerializer<React.ReactNode> = (
  type,
  props,
  _,
  children,
  key,
) => {
  switch (type) {
    case Elements.preformatted: {
      const { text = '' } = props as { text?: string };

      return <Box {...{ key }} dangerouslySetInnerHTML={{ __html: text }} />;
    }
    case Elements.hyperlink: {
      const { data } = props as { data: PrismicContentLink };

      if (data.link_type !== 'Web' || !data.url) {
        return null;
      }

      return (
        <Link locale={false} {...{ key }} href={normalizeHref(data.url)}>
          {children}
        </Link>
      );
    }
    case Elements.image: {
      const { url, dimensions, linkTo } = props as {
        linkTo: PrismicContentLink | null;
        alt: string | null;
        dimensions: { width: number; height: number } | null;
        url: string | null;
      };

      if (!url || !dimensions) {
        return null;
      }

      const img = (
        <Image
          src={url}
          key={linkTo?.link_type === 'Web' && linkTo?.url ? undefined : key}
          width={dimensions.width}
          height={dimensions.height}
        />
      );

      return linkTo?.link_type === 'Web' && linkTo?.url ? (
        <Link {...{ key }} href={linkTo.url}>
          {img}
        </Link>
      ) : (
        img
      );
    }
    default:
      return null;
  }
};

const RichTextRenderer: React.FC<RichTextRendererProps> = React.memo(
  ({ content }) => (
    <RichText render={content} htmlSerializer={htmlSerializer} />
  ),
);

export default RichTextRenderer;

import get from 'lodash/get';
import Prismic from '@prismicio/client';
import { Document } from '@prismicio/client/types/documents';
import { QueryOptions } from '@prismicio/client/types/ResolvedApi';
import { PreviewData } from 'next';

import { getConfig } from 'utils/config';
import { findKeyByValue } from 'utils/lodash/client';
import {
  AlternateLanguageUrls,
  AlternateLanguagesSlug,
  DocumentWithAlternateLanguages,
} from './types';
import { NEXT_LOCALE_TO_PRISMIC_LOCALE_MAPPING } from './consts';

export const normalizeHref = (href: string): string => {
  if (href.startsWith('https://#')) {
    return href.replace('https://#', '#');
  }

  if (href.startsWith('https:///#')) {
    return href.replace('https:///#', '#');
  }

  if (href.startsWith('https:///')) {
    return href.replace('https:///', '/');
  }

  return href;
};

export const makePrismicClient = (): ReturnType<typeof Prismic.client> =>
  Prismic.client(getConfig().serverRuntimeConfig.prismicProjectUrl);

export const queryAllPages = async (
  q: string | string[],
  options?: QueryOptions,
): Promise<Document[]> => {
  const prismicClient = makePrismicClient();
  let currentPage = 1;
  const commonOptions = {
    ...options,
    pageSize: 100,
  };
  const { results, total_pages: totalPages } = await prismicClient.query(q, {
    ...commonOptions,
    page: currentPage,
  });
  const allResults = [...results];

  while (currentPage < totalPages) {
    currentPage += 1;
    // eslint-disable-next-line no-await-in-loop
    const { results: resultsForNextPage } = await prismicClient.query(q, {
      ...commonOptions,
      page: currentPage,
    });

    allResults.push(...resultsForNextPage);
  }

  return allResults;
};

export const tryGetRefFromPreviewData = (
  previewData: PreviewData,
): string | undefined => {
  if (!previewData || typeof previewData !== 'object') {
    return undefined;
  }

  return get(previewData, 'ref');
};

export const getAlternateLanguagesUrlsMap = async (
  docs: DocumentWithAlternateLanguages[],
  makeSlug: (url: string | undefined) => string | undefined = (
    url: string | undefined,
  ) => url,
  slugPath = 'data.slug',
): Promise<Partial<MappedObject<AlternateLanguagesSlug[]>>> => {
  const prismicClient = makePrismicClient();
  const alternateLanguageSlugsArray: AlternateLanguageUrls[] = await Promise.all(
    docs.map(({ id, alternateLanguages }) =>
      (async () => {
        const alternateDocs = await Promise.all(
          alternateLanguages.map((alternateLanguage) =>
            prismicClient.queryFirst(
              Prismic.predicates.at('document.id', alternateLanguage.id),
              {
                lang: '*',
              },
            ),
          ),
        );

        return {
          id,
          slugs: alternateDocs.map((alternateDoc) => ({
            lang:
              findKeyByValue(
                NEXT_LOCALE_TO_PRISMIC_LOCALE_MAPPING,
                alternateDoc.lang,
              ) || '',
            slug: makeSlug(get(alternateDoc, slugPath)) || '/',
          })),
        };
      })(),
    ),
  );

  return alternateLanguageSlugsArray.reduce<
    Partial<MappedObject<AlternateLanguagesSlug[]>>
  >(
    (result, { id, slugs }) => ({
      ...result,
      [id]: slugs,
    }),
    {},
  );
};
